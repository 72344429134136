import cx from 'classnames';
import s from './LoaderDots.module.scss';

type LoaderDotsProps = {
  isLoading: boolean;
  isDark: boolean;
  className?: string;
}

const LoaderDots = (props: LoaderDotsProps) => {
  const {
    isLoading,
    isDark = false,
    className = '',
  } = props;

  if (!isLoading) {
    return null;
  }

  return (
    <span className={cx(s.root, className, {
      [s.isDark]: isDark,
    })}>
      <span className={cx(s.dot)} />
      <span className={cx(s.dot)} />
      <span className={cx(s.dot)} />
    </span>
  );
}

export default LoaderDots;

