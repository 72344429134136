import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames';
import s from './PayoutsWallet.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import CardTitle from '@/shared/ui/CardTitle';
import CoinLabel from '@/shared/ui/CoinLabel';
import Copy from '@/shared/ui/Copy';
import DropdownMenu from '@/shared/ui/DropdownMenu';
import { toggleWithdrawalAddressModal } from '~/Accounts/Accounts.slice';
import {
  selectPayoutAddress,
  selectSelectedAccount,
  selectSelectedPayoutCoin,
} from '~/Accounts/Accounts.selectors';
import { shortWalletAddress } from '~/Accounts/utils/shortWalletAddress';
import { showSuccessToast } from '@/shared/utils/notify';
import ButtonText from '@/shared/ui/ButtonText';
import PayoutsPausedCountdown from '../PayoutsPausedCountdown';
import { sendGtmCustomEvent, gtmAccountType } from '@/processes/CookieBanner';
import { BTC_COIN, GRAM_COIN } from '@/shared/constants/common';

type PayoutsWalletProps = {};

const PayoutsWallet = (props: PayoutsWalletProps) => {
  const {} = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const { isMain, deactivated } = useSelector(selectSelectedAccount);
  const coin = useSelector(selectSelectedPayoutCoin);
  const isGRAM = GRAM_COIN === coin;
  const isBTC = BTC_COIN === coin;

  const {
    address,
    valid,
    isTag,
    memo,
    isPayoutsPaused,
    formattedPayoutsPausedTill,
  } = useSelector(state => selectPayoutAddress(state, isBTC ? null : coin));

  const handleAddClick = () => {
    dispatch(toggleWithdrawalAddressModal({ show: true }));
  }

  const handleEditClick = () => {
    sendGtmCustomEvent({
      action: 'payouts_withdrow_settings_edit_click',
      label: {
        account: gtmAccountType(isMain, deactivated),
        coin,
      }
    });
    dispatch(toggleWithdrawalAddressModal({ show: true, isEdit: true }));
  }

  const menuList = useMemo(() => ([
    {
      text: intl.formatMessage({ id: 'PayoutsWalletProps.edit' }),
      onSelect: handleEditClick,
    },
  ]), []); // eslint-disable-line

  const hasAddress = !!address;

  return (
    <div className={s.root}>
      <CardTitle
        title={intl.formatMessage({ id: 'PayoutsWallet.title', defaultMessage: 'Withdraw to' })}
        action={
          hasAddress ? null :
            <ButtonText
              onClick={handleAddClick}
            >
              <FormattedMessage id='PayoutsWallet.add' defaultMessage='Add' />
            </ButtonText>
        }
        inCard
      />

      <div
        className={cx(s.list, {
          [s.isEmpty]: !hasAddress,
        }, 'px-4 py-3')}
      >
        {
          hasAddress ? (
            <>
              <div className='flex justify-between items-center'>
                <div className={cx(s.wallet, 'text-left pr-3 basis-2/3 min-w-0')}>
                  <div className="mb-1">
                    <CoinLabel
                      coin={coin}
                      label={memo}
                      isWarning={!isTag && address.length !== 0 && isGRAM}
                    />
                  </div>

                  <div className={cx(s.address, 'text-xs')}>
                    <div className={s.addressText}>
                      {shortWalletAddress(address)}
                    </div>

                    <div className={s.addressCopy}>
                      <Copy
                        text={address}
                        label={intl.formatMessage({ id: 'PayoutsWallet.copy' })}
                        onCopyCallback={() => {
                          showSuccessToast(intl.formatMessage({ id: 'withdrawal.copied.toast.text'}, { walletName: memo } ))
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={cx(s.menu, 'text-right basis-1/3 flex justify-end')}>
                  <DropdownMenu
                    onClick={() => {
                      sendGtmCustomEvent({
                        action: 'payouts_withdrow_settings_click',
                        label: {
                          account: gtmAccountType(isMain, deactivated),
                          coin,
                        }
                      });
                    }}
                    menuList={menuList}
                  />
                </div>
              </div>
              {isPayoutsPaused ?
                <PayoutsPausedCountdown targetTime={formattedPayoutsPausedTill}/>
                : null}
            </>
          ) : (
            <FormattedMessage id='PayoutsWallet.noAddress' defaultMessage='No withdrawal address' />
          )
        }
      </div>
    </div>
  );
}

export default PayoutsWallet;
