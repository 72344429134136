import { useState, useEffect } from 'react';
import ConfirmationModal from './components/ConfirmationModal';

const Confirmation = () => {
  return (
    <ConfirmationModal/>
  );
}

export default Confirmation;
