const IconMore = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={11.5} cy={4} r={2} fill="currentColor" />
      <circle cx={11.5} cy={12} r={2} fill="currentColor" />
      <circle cx={11.5} cy={20} r={2} fill="currentColor" />
    </svg>
  )
}

export default IconMore;
