import api from '@/global/api';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _toLower from 'lodash/toLower';
import _toUpper from 'lodash/toUpper';
import qs from 'query-string';
import { Account } from './types';
import { convertNegativeToZero } from './utils/convertNegativeToZero';
import { accountCoins, networkCoins } from '@/shared/constants/common';

export type CreateAccountType = {
  name: string;
  about?: string;
}

export type AccountType = {
  name: string;
  about: string;
}

export type AccountsResponseType = {
  accounts: AccountType[];
}

export type EstimatedIncomeResponseType = {
  currentAmount: string;
  unverifiedAmount: string;
}

export const extendedApi = api.enhanceEndpoints({
  addTagTypes: ["Accounts", "PayoutAddress"],
}).injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query({
      query: () => ({
        url: `/v1/accounts`,
        method: 'GET',
      }),
      transformResponse: (response: AccountsResponseType) => { return response?.accounts || []; },
    }),
    getAccountByName: build.query({
      query: (name) => ({
        url: `/v1/accounts/${name}`,
        method: 'GET',
      }),
    }),
    getAccountsDetailsBatch: build.query({
      async queryFn(minerAccount = null, _queryApi, _extraOptions, baseQuery) {
        let accounts: any = [];
        // fetch single account if minerAccount passed as argument
        if (minerAccount) {
          const accountJSON = await baseQuery({
            url: `/v1/accounts/${minerAccount}`,
            method: 'GET',
          });
          const singleAccountData = _get(accountJSON, 'data.account', null);
          accounts.push(singleAccountData);
        // or, fetch all accounts
        } else {
          const accountsJSON = await baseQuery({
            url: `/v1/accounts`,
            method: 'GET',
          });
          accounts = _get(accountsJSON, 'data.accounts', []);
        }
        
        // account rewards stats
        let accountsRewardsStats: any[] = [];
        const accountsRewardsStatsPromises: any[] = [];
        accounts.forEach((account: any) => {
          accountCoins.forEach((coin: string) => {
            const query = qs.stringify({
              currency: _toUpper(coin),
            })
            const queryPromise = baseQuery({
              url: `/v1/accounts/${account.name}/rewards-stats?${query}`,
              method: 'GET',
            });
            accountsRewardsStatsPromises.push(queryPromise);
          })
        });
        // account estimated-income
        let accountsEstimatedIncome: any[] = [];
        const accountsEstimatedIncomePromises : any[] = [];
        accounts.forEach((account: any) => {
          accountCoins.forEach((coin: string) => {
            const coinPath = coin === 'BTC' ? 'sha256' : _toLower(coin);
            const queryPromise = baseQuery({
              url: `/v1/accounts/${account.name}/${coinPath}/estimated-income`,
              method: 'GET',
            });
            accountsEstimatedIncomePromises.push(queryPromise);
          })
        });
        // account miner stats
        let accountsMinerStats: any[] = [];
        const accountsMinerStatsPromises: any[] = [];
        accounts.forEach((account: any) => {
          accountCoins.forEach((coin: string) => {
            const coinPath = coin === 'BTC' ? 'sha256' : _toLower(coin);
            const query = qs.stringify({
              coin: _toUpper(coin),
            });
            const queryPromise = baseQuery({
              url: `/v1/accounts/${account.name}/${coinPath}/miner?${query}`,
              method: 'GET',
              
            });
            accountsMinerStatsPromises.push(queryPromise);
          })
        });

        try {
          // estimated-incom
          accountsEstimatedIncome = await Promise.all(accountsEstimatedIncomePromises);
          let j = 0;
          accounts.forEach((_account: AccountType, i: number) => {
            accountCoins.forEach((coin: string) => {
              const { currentAmount, unverifiedAmount } = accountsEstimatedIncome[j]?.data || {};
              accounts[i] = {
                ...accounts[i], 
                ...{
                  [coin]: {
                    estimated: {
                      currentAmount: Number(currentAmount || 0),
                      unverifiedAmount: Number(unverifiedAmount || 0),
                    },
                  }
                }
              }
              j++;
            })
          });

          // rewards
          accountsRewardsStats = await Promise.all(accountsRewardsStatsPromises);
          j = 0;
          accounts.forEach((_account: AccountType, i: number) => {
            accountCoins.forEach((coin: string) => {
              const rewards = accountsRewardsStats[j]?.data || {};
              const available = Number(_get(rewards, 'available', 0)) || 0;
              const locked = Number(_get(rewards, 'locked', 0)) || 0;
              const currentAmount = Number(_get(accounts[i], `${coin}.estimated.currentAmount`, 0)) || 0;
              const unverifiedAmount = Number(_get(accounts[i], `${coin}.estimated.unverifiedAmount`, 0)) || 0;

              accounts[i] = {
                ...accounts[i], 
                ...{
                  [coin]: {
                    balances: {
                      unpaid: available + locked + unverifiedAmount,
                      current: currentAmount,
                      available: available,
                      unconfirmed: locked + unverifiedAmount,
                    }
                  }
                }
              }
              j++;
            })
          });
          
          // miner stats
          accountsMinerStats = await Promise.all(accountsMinerStatsPromises);
          j = 0;
          accounts.forEach((_account: AccountType, i: number) => {
            accountCoins.forEach((coin: string) => {
              const hashrate = _get(accountsMinerStats, `${j}.data.hashrate`, 0);
              _set(accounts, `${i}.${coin}.hashrate`, convertNegativeToZero(hashrate));
              j++;
            })
          });
        } catch(e) {
          console.error(e);
        }
        
        return { data: accounts };
      },
      providesTags: ["Accounts"],
    }),    
    createSubaccount: build.mutation({
      query: (body: CreateAccountType) => ({
        url: `/v1/accounts`,
        method: 'POST',
        body,
      }),
    }),
    deactivateSubaccount: build.mutation({
      query: (name) => ({
        url: `/v1/accounts/${name}`,
        method: 'DELETE',
      }),
    }),
    updateSubaccount: build.mutation({
      query: ({ name, about }) => ({
        url: `/v1/accounts/${name}`,
        method: 'PUT',
        body: {
          about,
        }
      }),
      async onQueryStarted({ id, ...rest }, { dispatch, queryFulfilled }) {
        const minerAccount = rest.isSupport ? rest.name : null;
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getAccountsDetailsBatch', minerAccount, (draft) => {
            const updatedIndex = draft.findIndex((account: Account) => account.name === rest.name);
            draft[updatedIndex].about = rest.about;
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getEstimatedIncome: build.query({
      query: (name) => ({
        url: `/v1/accounts/${name}/sha256/estimated-income`,
        method: 'GET',
      }),
      transformResponse: (response: EstimatedIncomeResponseType) => { 
        const {
          currentAmount = 0,
          unverifiedAmount = 0,
        } = response || {};
        return {
          currentAmount: Number(currentAmount),
          unverifiedAmount: Number(unverifiedAmount),
          estimatedIncome: Number(currentAmount) + Number(unverifiedAmount),
        };
      }
    }),
    // payouts
    getPayoutAddress: build.query({
      query: (params = {}) => {
        const {
          coin,
          account,
        } = params;

        const upperCoin = _toUpper(coin);
        const network = _get(networkCoins, upperCoin);

        const queryParams = qs.stringify({ network, currency: upperCoin, }, { skipNull: true });

        return {
          url: `/v1/accounts/${account}/payout-address?${queryParams}`,
          method: 'GET',
        }
      },
      providesTags: (_result, _error, { account }) => {
        return [{ type: 'PayoutAddress', id: account }];
      },
    }),
    createPayoutAddress: build.mutation({
      query: (body = {}) => {
        const {
          account,
          network,
          currency
        } = body;

        const isBTC = currency === 'BTC';
        const queryParams = qs.stringify({ network, currency }, { skipNull: true });

        const baseUrl = `/v1/accounts/${account}/`;
        const url = isBTC ? `${baseUrl}/payout-address?${queryParams}` : `${baseUrl}/${_toLower(currency)}/payout-address?${queryParams}`;

        return {
          url: url,
          method: 'POST',
          body,
        }
      },
    }),
    getRewardsStats: build.query({
      query: (name) => ({
        url: `/v1/accounts/${name}/sha256/rewards-stats`,
        method: 'GET',
      }),
    }),
    checkAccountAvailability: build.query({
      query: (name) => ({
        url: `/v1/accounts/${name}/check`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateSubaccountMutation,
  useUpdateSubaccountMutation,
  useDeactivateSubaccountMutation,
  useGetAccountsQuery,
  useGetAccountByNameQuery,
  useGetAccountsDetailsBatchQuery,
  useGetPayoutAddressQuery,
  useCreatePayoutAddressMutation,
  useGetRewardsStatsQuery,
  useGetEstimatedIncomeQuery,
  useLazyCheckAccountAvailabilityQuery,
} = extendedApi;

export const {
  createSubaccount,
  updateSubaccount,
  deactivateSubaccount,
  getAccounts,
  getAccountByName,
  getAccountsDetailsBatch,
  getPayoutAddress,
  createPayoutAddress,
  getRewardsStats,
  getEstimatedIncome,
  checkAccountAvailability,
} = extendedApi.endpoints;
