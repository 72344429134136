const IconKaspa = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2500 2500"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#49EACB"
        d="M2500 1237.5c0 167.2-68.9 328.2-129.4 474.5-62.6 150.5-154.5 290.6-267.2 403.4-112.8 112.9-250.6 211.1-400.9 273.8-146.2 60.6-309 110.8-476 110.8s-334.1-41.8-480.2-102.4C596 2334.9 493.7 2199 378.8 2086.1 266 1973.3 138.6 1866.6 76 1714.1c-60.5-146.3-73.1-309.4-73.1-476.6s-18.8-344.9 39.7-491.2c62.6-150.5 194.2-271.7 309-384.6C464.3 248.8 591.7 129.6 742 66.9 888.2 6.3 1059.4 0 1226.4 0s329.9 29.3 474 89.9c150.3 62.7 296.5 148.4 409.2 261.3 112.8 112.9 213 252.9 273.5 403.4 60.5 148.4 116.9 315.6 116.9 482.9z"
      />
      <path
        fill="#FFF"
        d="M1575.1 524.7L1309.9 564.4 1387.2 1078.6 831.8 650.1 668.9 863.3 1155.4 1237.5 668.9 1613.7 831.8 1826.9 1387.2 1398.4 1309.9 1912.6 1575.1 1952.3 1681.6 1237.5z"
      />
    </svg>
  )
}

export default IconKaspa;
