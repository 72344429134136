import * as React from 'react';

const ChevronDown = (props: any) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.36 8.232a1 1 0 0 1 1.408.128L12 13.438l4.232-5.078a1 1 0 1 1 1.536 1.28l-5 6a1 1 0 0 1-1.536 0l-5-6a1 1 0 0 1 .128-1.408Z"
      fill="currentColor"
    />
  </svg>

);

export default ChevronDown;
