const IconExit = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#clip0_1667_34202)"
      stroke="currentColor"
      strokeLinecap="round"
    >
      <path d="M9.334 1.333h-6a2 2 0 00-2 2v9.334a2 2 0 002 2h6" />
      <path
        d="M11.146 11.666L14.666 8m0 0l-3.52-3.667M14.666 8h-8"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1667_34202">
        <path fill="currentColor" d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default IconExit;
