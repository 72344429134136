import _get from 'lodash/get';
import { toFixed } from '@/shared/utils/toFixed';

const COIN_DECIMALS = {
  BTC: 8,
  RVN: 2,
  KAS: 2,
  GRAM: 2,
  DNX: 2,
  ETC: 4,
}

export const formatCrypto = (value: string | number, coin: string) => {
  const decimals = _get(COIN_DECIMALS, coin, 5);
  const amount = toFixed(value, decimals);
  return `${amount} ${coin}`;
}

export const formatCryptoAmount = (value: string | number, coin: string) => {
  const decimals = _get(COIN_DECIMALS, coin, 5);

  return toFixed(value, decimals);
}

export const addCoinPostfix = (value: string | number, coin: string) => {
  return `${value} ${coin}`;
}