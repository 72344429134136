import Confirmation from './Confirmation';

export { default as ConfirmationSlice } from './Confirmation.slice';
export * from './Confirmation.slice';
export * from './constants/confirmation';
export { 
  useSendConfirmationMutation,  
  sendConfirmation,
} from './api';

export default Confirmation;
