const IconCopy = (props: any) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.663 11.958c0 .532.43.963.962.963h5.833c.532 0 .963-.431.963-.963v-1.37h1.954a.963.963 0 00.963-.963V2.042a.962.962 0 00-.963-.963H5.542a.963.963 0 00-.963.963v1.37H2.625a.962.962 0 00-.962.963v7.583zm3.675-8.545V2.042c0-.113.091-.204.204-.204h5.833c.113 0 .204.091.204.204v7.583a.204.204 0 01-.204.204H9.421V4.375a.962.962 0 00-.963-.962h-3.12zm-2.713 8.75a.204.204 0 01-.204-.205V4.375c0-.113.091-.204.204-.204h5.833c.113 0 .205.091.205.204v7.583a.204.204 0 01-.205.205H2.625z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconCopy;
