const IconArrowLeft = (props: any) => {
  return (
    <svg
      width={11}
      height={18}
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.42 8.712c0 .337.125.615.396.879l7.34 7.104c.212.213.475.323.776.323.622 0 1.113-.491 1.113-1.106 0-.315-.125-.586-.344-.806L3.065 8.705 9.7 2.31A1.102 1.102 0 008.932.407a1.05 1.05 0 00-.777.314L.816 7.833c-.27.264-.395.535-.395.879z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconArrowLeft
