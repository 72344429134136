import React, { useState } from 'react';
import * as PopoverLib from '@radix-ui/react-popover';
import cx from 'classnames';
import s from './Popover.module.scss';

type PopoverProps = {
  triggerComponent: React.ReactNode;
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (state: boolean) => void;
  className?: string;
  modal?: boolean;
  sideOffset?: number;
  align?: "start" | "center" | "end";
}

const Popover = (props: PopoverProps) => {
  const {
    triggerComponent,
    children,
    className = '',
    align = 'end',
    sideOffset = 5,
    modal = false,
    open,
    onOpenChange = () => {},
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const openState = typeof open === 'boolean' ? open : isOpen;
  const onOpenChangeState = typeof open === 'boolean' ? onOpenChange : setIsOpen;

  return (
    <>
      <PopoverLib.Root
        modal={modal}
        open={openState}
        onOpenChange={(value) => onOpenChangeState(value)}
      >
        <PopoverLib.Trigger asChild>
          {triggerComponent}
        </PopoverLib.Trigger>
        <PopoverLib.Portal>
          <PopoverLib.Content
            data-no-focus-lock
            onEscapeKeyDown={() => { onOpenChangeState(false); } }
            align={align}
            asChild
            sideOffset={sideOffset}
            className={cx(s.popoverContent, className)}
          >
            {children}
          </PopoverLib.Content>
        </PopoverLib.Portal>
      </PopoverLib.Root>
    </>
  );
}

export default Popover;
