import * as React from 'react';

const IconResend = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 16c4.377 0 8-3.623 8-8 0-4.369-3.631-8-8.008-8C3.624 0 0 3.631 0 8c0 4.377 3.631 8 8 8zM3.976 8.886c0-1.78 1.263-2.957 3.153-2.957h2.298l.808.032-.533-.416-.792-.769a.576.576 0 01-.188-.423c0-.337.25-.604.588-.604.18 0 .314.063.431.18l2.047 2.079a.704.704 0 01.212.494.66.66 0 01-.212.494L9.733 9.098a.585.585 0 01-.431.173.59.59 0 01-.58-.604c0-.165.07-.314.188-.432l.949-.91.368-.266-.8.039H7.13c-1.192 0-1.984.714-1.984 1.788 0 1.153.808 1.922 1.992 1.922h1.075c.368 0 .612.243.612.588 0 .353-.251.58-.628.58H7.137c-1.89 0-3.16-1.223-3.16-3.09z"
      fill="currentColor"
    />
  </svg>
);

export default IconResend;
