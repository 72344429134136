const IconSortUnfold = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.29 14.29 12 18.59l-4.29-4.3a1.004 1.004 0 1 0-1.42 1.42l5 5a1.002 1.002 0 0 0 1.42 0l5-5a1.004 1.004 0 0 0-1.42-1.42ZM7.71 9.71 12 5.41l4.29 4.3a1 1 0 0 0 1.639-.325 1 1 0 0 0-.219-1.095l-5-5a1 1 0 0 0-1.42 0l-5 5a1.004 1.004 0 1 0 1.42 1.42Z"
        fill="currentColor"
      />
    </svg>

  )
}

export default IconSortUnfold
