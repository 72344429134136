import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import _defaults from 'lodash/defaults';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';

const defaultOptions = {
  googleTagManagerID: 'GTM-WDVP8KH',
};

export const GDPR_GA = 'settings-gdpr-GA-accept';
export const GDPR_GTM = 'settings-gdpr-GTM-accept';
export const GDPR_FB = 'settings-gdpr-FB-accept';
export const GDPR_HJ = 'settings-gdpr-hotjar-accept';

export const isGdprAnalyticsAllowed = () => {
  return Cookies.get(GDPR_GA) === 'true'
      && Cookies.get(GDPR_GTM) === 'true'
      && Cookies.get(GDPR_FB) === 'true'
      && Cookies.get(GDPR_HJ) === 'true';
};

export const isAllGdprNotSet = () =>
  !Cookies.get(GDPR_GA)
  && !Cookies.get(GDPR_GTM)
  && !Cookies.get(GDPR_FB)
  && !Cookies.get(GDPR_HJ);


export const initializeAndTrack = (options = {}) => {
    // init for client side only
    if (typeof window === 'undefined') { return; }

    const settings = _defaults(options, defaultOptions);

    if (!isGdprAnalyticsAllowed()) { return; }

    // Google Tag Manager
    TagManager.initialize({ gtmId: settings.googleTagManagerID });
};

/**
 * GTM dataLayer push
 * @param {object} dataLayerObject - dataLayer push object
 */
export const gtmPush = (dataLayerObject: any) => {
    isGdprAnalyticsAllowed() && TagManager.dataLayer(dataLayerObject);
};

/**
 * Manual event sending. It's not using any native ga libs to avoid cookies set and personal data embedding
 * @param {string} tid 
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const sendAnonymusBannerStat = (tid = '', category = '', action = '', label = '') => {
  if (!tid) {
      return;
  }
  const tempClientId = uuidv4();
  const params = queryString.stringify({
    v: 1,
    tid: tid,
    cid: tempClientId,
    t: 'event',
    ec: category,
    ea: action,
    el: label,
    aip: 1,
    geoid: 'notset',
  });
  fetch(`https://www.google-analytics.com/collect?${params}`);
}
