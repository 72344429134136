const IconClear = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.235 1.235a.375.375 0 01.53 0L5 4.47l3.235-3.235a.375.375 0 01.53.53L5.53 5l3.235 3.235a.375.375 0 01-.53.53L5 5.53 1.765 8.765a.375.375 0 01-.53-.53L4.47 5 1.235 1.765a.375.375 0 010-.53z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.3}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconClear;
