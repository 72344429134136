import * as React from 'react';

const IconInfo = (props: any) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.028 8.919h1.42V6.167h-1.42V5.085h2.469V8.92h1.492V10H5.028V8.919zm1.226-5.17c0-.204.062-.377.186-.521.124-.145.296-.217.516-.217.226 0 .406.072.54.217a.737.737 0 01.202.52c0 .21-.067.384-.201.522-.135.13-.315.196-.54.196-.221 0-.393-.065-.517-.196a.75.75 0 01-.186-.521z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13A6 6 0 107 1a6 6 0 000 12zm0 1A7 7 0 107 0a7 7 0 000 14z"
      fill="currentColor"
    />
  </svg>
);

export default IconInfo;
