import { useId } from 'react';
const IconGram = (props: any) => {
  const id = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 56 56"
      {...props}
    >
      <g clipPath={`url(${id})`}>
        <g>
          <path
            fill="#000"
            d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
          ></path>
          <path
            stroke="#fff"
            strokeOpacity="0.15"
            d="M55.5 28c0 15.188-12.312 27.5-27.5 27.5S.5 43.188.5 28 12.812.5 28 .5 55.5 12.812 55.5 28z"
          ></path>
        </g>
        <path
          fill="#fff"
          d="M37.561 15.628H18.44c-3.515 0-5.744 3.792-3.975 6.858l11.801 20.455c.77 1.336 2.7 1.336 3.47 0L41.54 22.486c1.766-3.06-.462-6.858-3.976-6.858h-.002zM26.256 36.807l-2.57-4.974-6.202-11.091c-.409-.71.096-1.62.953-1.62h7.816v17.687l.003-.002zm12.256-16.068l-6.2 11.096-2.57 4.972V19.12h7.817c.856 0 1.362.91.953 1.62z"
        ></path>
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0H56V56H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconGram;
