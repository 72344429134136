import cx from 'classnames';
import s from './TextLoader.module.scss';

type TextLoaderProps = {
  width?: string,
  height?: string,
  className?: string,
}

const TextLoader = (props: TextLoaderProps) => {
  const {
    width = "100px",
    height = "20px",
    className,
    ...restProps
  } = props;

  const styles = {
    width,
    height,
  }

  return (
    <div
      className={cx(s.root, className)}
      style={styles}
      {...restProps}
    />
  );
}

export default TextLoader;
