import React, { createContext, useState, useEffect } from 'react';

import cx from 'classnames';
import s from './SwipeList.module.scss';
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web'

const { Provider, Consumer } = React.createContext({});
/* -------------------------------------------------------------------------------------------------
 * SwipeList
 * -----------------------------------------------------------------------------------------------*/

type SwipeListProps = {
  defaultValue: string;
  firstScreen: string;
  className?: string;
  children: React.ReactNode;
}

const SwipeList = (props: SwipeListProps) => {
  const {
    defaultValue,
    firstScreen,
    className = 'h-[50vh]',
    children,
  } = props;

  const transRef = useSpringRef();
  const directionAnimation = firstScreen === defaultValue ? {
    from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(50%,0,0)' },
  } : {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  }
  const transitions = useTransition(defaultValue, {
    ref: transRef,
    keys: null,
    ...directionAnimation,
  })

  useEffect(() => {
    transRef.start();
  }, [defaultValue])

  return (
    <Provider value={{ defaultValue, transRef, transitions }}>
      <div className={cx(s.list, className)}>
        {children}
      </div>
    </Provider>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SwipeHead
 * -----------------------------------------------------------------------------------------------*/
type SwipeHeadProps = {
  title?: string;
  className?: string;
  action: React.ReactNode;
  onDismiss: () => void;
}

const SwipeHead = (props: SwipeHeadProps) => {
  const {
    title,
    action,
    className = '',
    onDismiss,
  } = props;

  return (
    <div className={cx('flex my-5', className)}>
      <div className="inline-flex items-center w-1/4">
        <button 
          onClick={onDismiss}
          className={s.actionBtn}
        >
          {action}
        </button>
      </div>
      <div className="text-center flex-grow">{title}</div>
      <div className="w-1/4" aria-hidden="true"/>
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SwipePage
 * -----------------------------------------------------------------------------------------------*/
type SwipePageProps = {
  value: string;  
  title?: string;
  className?: string;
  children: React.ReactNode;
  action: React.ReactNode;
  onDismiss: () => void;
}

const SwipePage = (props: SwipePageProps) => {
  const {
    title,
    value,
    action,
    className = '',
    onDismiss,
    children,
  } = props;

  return (
    <Consumer>
      {({ defaultValue, transitions }: any) => {
        return transitions((style: any, item: any) => {
          if (value !== defaultValue) { return null; }
          return (
            <animated.div style={style} className={s.page}>
              <SwipeHead
                action={action}
                onDismiss={onDismiss}
                title={title}
              />
              <div className={cx('overflow-y-auto', className)}>
                {children}
              </div>
            </animated.div>
          )
        })
      }}
    </Consumer>
  );
}

const Root = SwipeList;
const Page = SwipePage;
const Head = SwipeHead;

export {
  Root,
  Page,
  Head,
  SwipeList,
  SwipePage,
  SwipeHead,
};

