import _get from 'lodash/get';
import cx from 'classnames';
import s from './CoinLabel.module.scss';
import { COINS_MAP } from "@/shared/constants/common";
import { formatCryptoAmount } from '@/shared/utils/cryptoFormatter';
import { IconWarning } from '@/shared/ui/Icons';

type CoinLabelProps = {
  coin: string;
  label?: string;
  balance?: string | number;
  isShort?: boolean;
  hasIcon?: boolean;
  className?: string;
  withLabel?: boolean;
  showZeroBalance?: boolean;
  isWarning?: boolean;
};

const CoinLabel = (props: CoinLabelProps) => {
  const {
    coin = '',
    label = '',
    isShort = false,
    hasIcon = true,
    balance = 0,
    className = 'w-4',
    withLabel = true,
    showZeroBalance = false,
    isWarning = false,
  } = props;

  const Icon = _get(COINS_MAP, [coin, 'icon']);
  const coinName = _get(COINS_MAP, [coin, 'name'], coin);
  const coinSymbol = _get(COINS_MAP, [coin, 'symbol'], coin);
  const text = label || (isShort ? coinSymbol : coinName);

  return (
    <div className={cx(s.root, {
      'flex items-center justify-start': hasIcon,
      'inline-block': !hasIcon,
    })}>
      {
        (hasIcon && Icon) ? (
          <div className="relative">
            <Icon className={cx(s.icon, className)}/>
            { isWarning ? <div className="absolute -top-1 right-[3px]"><IconWarning width={8} height={8}/></div> : null }
          </div>
        ) : null
      }

      {
        (balance || showZeroBalance) ? (
          <span className='mr-1'>{formatCryptoAmount(balance, coin)}</span>
        ) : null
      }

      {withLabel ? (
        <span>{text}</span>
      ) : null}
    </div>
  );
}

export default CoinLabel;
