import api from '@/global/api';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendConfirmation: build.mutation({
      query: () => ({
        url: `/oauth/confirmation`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useSendConfirmationMutation,
} = extendedApi;

export const {
  sendConfirmation,
} = extendedApi.endpoints;
