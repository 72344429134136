import { useId } from 'react';
const IconDynex = (props: any) => {
  const id = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect
        width="24"
        height="24"
        fill={`url(#${id})`}
        rx="12"
      ></rect>
      <path
        fill="#fff"
        d="M18.878 7.284l-5.97-3.533a1.782 1.782 0 00-1.822 0l-5.97 3.533c-.562.332-.909.95-.909 1.616v7.067c0 .667.347 1.283.911 1.615 0 0 2.988 1.77 4.737 2.803.525.312 1.175-.047 1.23-.648l.004-.083V9.498L12 8.96l.91.538v10.156l.004.083c.056.6.705.959 1.23.649l4.737-2.804a1.876 1.876 0 00.91-1.615V8.9a1.88 1.88 0 00-.912-1.616zM9.59 18.454l-3.723-2.203a.33.33 0 01-.16-.286v-3.091a.335.335 0 01.16-.286l3.724-2.203v8.07H9.59zM5.706 10.91V8.9c0-.117.06-.226.16-.285l5.97-3.534a.315.315 0 01.32 0l5.97 3.534a.33.33 0 01.16.285v2.01l-6.289-3.725-6.29 3.724zm12.583 5.058c0 .117-.06.226-.16.286l-3.723 2.201v-8.068l3.722 2.203"
      ></path>
      <defs>
        <linearGradient
          id={id}
          x1="12"
          x2="12"
          y1="0"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30EBE5"></stop>
          <stop offset="1" stopColor="#0771FB"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconDynex;
