const IconWarning = (props: any) => (
  <svg
    width={15}
    height={12}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.799 10.333L7.93.533A1.06 1.06 0 007 0c-.4 0-.734.2-.932.534L.2 10.334c-.2.333-.2.799 0 1.132.133.334.534.534.932.534h11.734c.399 0 .8-.2 1-.599.2-.333.2-.734-.067-1.068zm-6.133-.666c0 .2-.133.333-.333.333h-.667c-.2 0-.333-.133-.333-.333V9c0-.2.132-.333.333-.333h.667c.2 0 .333.132.333.333v.667zm0-3c0 .4-.266.666-.667.666-.4 0-.666-.265-.666-.666v-2c0-.401.265-.667.666-.667s.667.266.667.667v2z"
      fill="#FFAE00"
    />
  </svg>
);

export default IconWarning;
