import React, { forwardRef } from 'react';
import { useState, useEffect } from 'react';
import cx from 'classnames';
import { firstChar } from '@/shared/utils/firstChar';
import s from './Avatar.module.scss';

const SIZES = ['sm', 'xl'];
type AvatarProps = {
  name: string;
  className?: string;
  tag?: 'button' | 'span';
  size?: typeof SIZES[number];
  [x: string]: any;
}

const Avatar = forwardRef<HTMLButtonElement, AvatarProps>((props, ref) => {
  const {
    name = '',
    className = '',
    tag = 'button',
    size = SIZES[0],
    ...restProps
  } = props;

  const ComponentTag = tag;

  return (
    <ComponentTag
      className={cx(className, s.avatar, s[size])} 
      ref={ref}
      {...restProps}
    >
      {firstChar(name)}
    </ComponentTag>
  );
});

Avatar.displayName = 'Avatar';

export default Avatar;
