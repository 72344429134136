const IconCheckmark = (props: any) => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.25 4.97a.75.75 0 010 1.06L9.959 18.323a1.75 1.75 0 01-2.475 0L2.69 13.53a.75.75 0 111.06-1.06l4.794 4.793a.25.25 0 00.353 0L21.19 4.97a.75.75 0 011.06 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconCheckmark;
