
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  isShow: false,
  gtmLabel: '',
  confirmationType: null,
  successMessage: null,
  successCallback: null,
  failureMessage: null,
}

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    toggleConfimation: (state, { payload }) => {
      state.isShow = payload.isShow;
      state.confirmationType = payload.confirmationType || null;
    },
    setConfirmationCallbackState: (state, { payload }) => {
      state.gtmLabel = payload.gtmLabel || '';
      state.successMessage = payload.successMessage || null;
      state.successCallback = payload.successCallback || null;
    },
  },
})

export const { 
  toggleConfimation,
  setConfirmationCallbackState,
} = confirmationSlice.actions;

export default confirmationSlice.reducer
