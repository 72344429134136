import React, { forwardRef, useState, useEffect } from 'react';
import cx from 'classnames';
import s from './Box.module.scss';

type BoxProps = {
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'none';
  withBorder?: boolean;
  colorSchema?: 'primary' | 'warning';
  [x:string]: any;
}

const Box = forwardRef((props: BoxProps, ref) => {
  const { 
    children, 
    className = '', 
    withBorder = true, 
    size = 'base', 
    colorSchema = 'primary',
    ...restProps 
  } = props;

  return (
    <div 
      className={cx(s.box, className, s[colorSchema], s[size], withBorder && s.withBorder)} 
      // @ts-ignore
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  );
})

Box.displayName = 'Box';

export default Box;
