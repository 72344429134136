const IconArrowRight = (props: any) => {
  return (
    <svg
      width={6}
      height={11}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.854 5.854a.5.5 0 000-.708L.56 1.854a.5.5 0 11.707-.708L4.56 4.44a1.5 1.5 0 010 2.122L1.268 9.854a.5.5 0 01-.707-.708l3.293-3.292z"
        fill="currentColor"
      />
      <path
        d="M3.783 5.217a.4.4 0 010 .566L.49 9.076a.6.6 0 10.848.848l3.293-3.293a1.6 1.6 0 000-2.262L1.338 1.076a.6.6 0 10-.848.848l3.293 3.293z"
        stroke="currentColor"
        strokeWidth={0.2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconArrowRight
