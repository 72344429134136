const IconProduct = (props: any) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 1.75a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM3.5 7A1.75 1.75 0 110 7a1.75 1.75 0 013.5 0zm-1.75 7a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm7-12.25a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM7 8.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm1.75 3.5a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zm3.5-8.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM14 7a1.75 1.75 0 11-3.5 0A1.75 1.75 0 0114 7zm-1.75 7a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconProduct;
