const IconCookie = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 30"
      {...props}
    >
      <g clipPath="url(#clip0_4477_190631)">
        <mask
          id="mask0_4477_190631"
          style={{ maskType: "luminance" }}
          width="31"
          height="30"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M30.497 0H.502v30h29.995V0z"></path>
        </mask>
        <g mask="url(#mask0_4477_190631)">
          <path
            fill="#fff"
            d="M8.997 19.75v.09a9.746 9.746 0 008.645-2.695c2.35-2.35 3.24-5.6 2.69-8.645h-.09c-6.215 0-11.25 5.035-11.25 11.25h.005zm6-11.75a.5.5 0 100-1 .5.5 0 000 1zm-7.75 8.5a.75.75 0 100-1.5.75.75 0 000 1.5zm1.5-11a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm2 5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm-6-.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
          ></path>
          <path
            fill="#fff"
            d="M10.752 20.492c-2.625 0-5.25-1-7.25-3-4-3.995-4-10.5 0-14.495A10.2 10.2 0 016.852.762a.495.495 0 01.65.275.495.495 0 01-.275.65 9.22 9.22 0 00-3.02 2.015c-3.605 3.61-3.605 9.48 0 13.085 3.605 3.605 9.48 3.605 13.085 0a9.232 9.232 0 002.58-8.065 3.013 3.013 0 01-2.585-2.515 3.003 3.003 0 01-2.5-2.5 3.013 3.013 0 01-2.515-2.585 9.2 9.2 0 00-2.16-.105.51.51 0 01-.535-.465.503.503 0 01.465-.535c.94-.065 1.885 0 2.81.195.23.05.395.25.395.49v.06a2.004 2.004 0 002 1.975c.275 0 .5.225.5.5a2 2 0 002 2c.275 0 .5.225.5.5a2 2 0 001.975 2h.06c.235 0 .44.165.49.4a10.21 10.21 0 01-2.78 9.355c-2 2-4.625 3-7.25 3h.01z"
          ></path>
          <g filter="url(#filter0_i_4477_190631)">
            <path
              fill="url(#paint0_radial_4477_190631)"
              d="M20.246 30c5.661 0 10.25-4.59 10.25-10.25S25.907 9.5 20.246 9.5c-5.66 0-10.25 4.59-10.25 10.25S14.586 30 20.246 30z"
            ></path>
          </g>
          <path
            fill="#1B1B1B"
            d="M25.996 22a.5.5 0 100-1 .5.5 0 000 1zm-1.25-6a.75.75 0 100-1.5.75.75 0 000 1.5zm-9.5 0a.75.75 0 100-1.5.75.75 0 000 1.5zm5.5 10.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm0-6a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm-5 2.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_4477_190631"
          width="20.5"
          height="24.5"
          x="9.996"
          y="9.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.615686 0 0 0 0 0.0117647 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_4477_190631"
          ></feBlend>
        </filter>
        <radialGradient
          id="paint0_radial_4477_190631"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(137.643 10.772 10.787) scale(23.0054 31.6949)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.218" stopColor="#FDA304"></stop>
          <stop offset="1" stopColor="#FE4603"></stop>
        </radialGradient>
        <clipPath id="clip0_4477_190631">
          <path fill="#fff" d="M0 0H31V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconCookie;
