import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import InputCode from '@/shared/ui/InputCode';
import confirmation2FAImage from '@/shared/assets/confirmation-2fa.png';
import { CONFIRMATION_CODE_LENGTH } from '../../constants/confirmation';

type Confirmation2FAProps = {
  onComplete?: (code: string) => void,
  onChange?: (code: string) => void,
  code?: any,
}

const Confirmation2FA = (props: Confirmation2FAProps) => {
  const {
    onComplete,
    onChange,
    code,
  } = props;

  return (
    <div className="text-center">
      <img 
        src={confirmation2FAImage.src} 
        alt="2FA" 
        className='max-w-[155px] mx-auto mb-5' 
      />
      <h3 className='text-2xl text-white mb-1'>
        <FormattedMessage id="conf.2fa.title"/>
      </h3>
      <p className='text-xs text-white leading-[18px] mb-6'>
        <FormattedMessage 
          id="conf.2fa.desc"
          values={{
            br: <br/>,
            a: (chunks) => <span className='text-[#FF5F01]'>{chunks}</span>
          }}
        />
      </p>
      <InputCode
        len={CONFIRMATION_CODE_LENGTH}
        onComplete={onComplete}
        onChange={onChange}
        value={code}
      />
    </div>
  );
}

export default Confirmation2FA;
