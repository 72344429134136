import React, { useState, useRef, useCallback } from "react";
import cx from 'classnames';
import {
  offset,
  flip,
  shift,
  autoUpdate,
  arrow,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss
} from "@floating-ui/react";
import s from "./Tooltip.module.scss";

type TooltipProps = {
  ToggleComponent: React.ReactElement | React.ReactNode;
  title?: string;
  className?: string;
  tooltipClassName?: string;
  placementPos?: any;
  flipOptions?: object;
  offset?: number;
  hideTooltip?: boolean;
  children?: React.ReactNode;
}

const Tooltip = (props: TooltipProps) => {

  const {
    ToggleComponent,
    title = '',
    className = '',
    tooltipClassName = '',
    placementPos = 'bottom',
    flipOptions = { padding: 100 },
    offset: offsetOption = 4,
    hideTooltip,
    children
  } = props;

  const [open, setOpen] = useState(false);
  const arrowRef = useRef<HTMLDivElement>(null);

  const onOpenChange = useCallback((open: boolean) => {
    if(!hideTooltip) {
      setOpen(open);
    } else {
      setOpen(false);
    }
  }, [hideTooltip]);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: placementPos,
    open,
    onOpenChange: onOpenChange,
    middleware: [
      offset(offsetOption),
      flip(flipOptions),
      shift({ padding: 8 }),
      arrow({element: arrowRef}),
    ],
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);
  return (
    <>
      <div
        ref={reference}
        {...getReferenceProps()}
        className={cx(s.root, className)}
      >
        { ToggleComponent }
      </div>

      {open && (
        <div
          ref={floating}
          className={cx(s.tooltip, tooltipClassName )}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
        >
          {
            title ? (
              <div className={s.title}>{title}</div>
            ) : null
          }
          <div className={s.content}>
            {children}
          </div>

        </div>
      )}
    </>
  )
}

export default Tooltip;