const IconRvn = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#fff"
        stroke="#BCBCBC"
        d="M14.833 8A6.833 6.833 0 111.166 8a6.833 6.833 0 0113.667 0z"
      ></path>
      <path
        fill="#384182"
        d="M7.636 11.322l-2.803 2.012 1.78-8.186 1.023 6.174zm.056-.019l1.707.022-2.72-6.145 1.013 6.123zm1.867-.135l1.307-4.842-.495-.605-.812 5.447zm-.63-7.59L6.78 5.024l3.142-1.017-.991-.43zm-.27 0l-1.335.043-.195.557 1.53-.6z"
      ></path>
      <path
        fill="#F79433"
        d="M10.277 5.535l-3.441-.472 3.101-.976.34 1.448zm-.815-1.82l.189-.513-.992-.431.803.944zm.286-.564l.224.806 1.322-.349-1.546-.457z"
      ></path>
      <path
        fill="#F05239"
        d="M9.474 11.293l-2.758-6.17 3.596.503-.838 5.667zm-.648-7.716l-1.7.664-.41.756 2.11-1.42zM7.77 3.073l-.425.47 1.41-.035-.985-.435zm.743-.365l-.674.312 1.537.677-.863-.99zm.768-.041h-.724l1.12.434-.396-.434zm.419.576l-.173.516.346.167-.173-.683z"
      ></path>
    </svg>
  )
}

export default IconRvn;
